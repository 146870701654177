import request from '@/utils/request'

// 查询客户订购详情
export function findAgentSubscribeOrder(id) {
  return request({
    url: `/agent_subscribe_orders/${id}`,
    method: 'get'
  })
}

// 查询客户订购详情卡号列表
export function findSimCardSubscribeOrders(id, params) {
  return request({
    url: `/agent_subscribe_orders/${id}/sim_card_subscribe_orders`,
    method: 'get',
    params: params
  })
}

// 导出订购详情卡号列表
export function exportSimCardSubscribeOrders(id, data) {
  return request({
    url: `/agent_subscribe_orders/${id}/sim_card_subscribe_orders/export`,
    method: 'post',
    data
  })
}

